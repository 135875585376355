var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.searchSurfWaiversLoading)?_c('WaveLoader'):_vm._e(),_c('header',{staticClass:"w-full sticky top-0 z-10"},[_c('div',{staticClass:"w-full bg-gray-700"},[_c('div',{staticClass:"container mx-auto py-2 lg:p-8 lg:py-3"},[_c('div',{staticClass:"w-full flex justify-between items-center p-2 text-white"},[_c('div',{staticClass:"text-white p-5"},[_c('WaveLogo',{staticClass:"cursor-pointer h-16 md:h-12 lg:h-16"})],1),_vm._m(0),_c('div',[_c('p',{staticClass:"m-0"},[_vm._v(" "+_vm._s(_vm._f("moment")(new Date(),"dddd Do MMMM"))+" ")])])])])])]),_c('main',{staticClass:"container mx-auto py-2 lg:p-8 lg:py-3"},[_vm._m(1),_c('section',{staticClass:"px-4 mb-15"},[_vm._m(2),_vm._l((_vm.surfersToCheckIn),function(surfer){return _c('div',{key:surfer.uuid,staticClass:"flex flex-wrap items-center justify-between -mx-2 lg:-mx-4"},[_c('div',{staticClass:"flex-auto md:flex justify-around items-center"},[_c('div',{staticClass:"md:w-1/2 m-2 lg:m-4"},[_c('p',[_vm._v(_vm._s(surfer.lastName)+", "+_vm._s(surfer.firstName))]),_c('p',{staticClass:"text-xs",domProps:{"innerHTML":_vm._s(surfer.sessionNames)}})]),_c('div',{staticClass:"md:w-1/2 m-2 lg:m-4"},[_c('p',[_vm._v("Session(s) at "+_vm._s(surfer.sessionTimes))])])]),_c('div',{staticClass:"w-1/2 md:w-1/3 m-2 lg:m-4 flex justify-around items-center"},[_c('div',{staticClass:"w-auto"},[_c('span',{staticClass:"relative inline-block flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:shadow-outline",class:surfer.onSite
									? 'bg-pear-600'
									: 'bg-gray-400',attrs:{"role":"checkbox","aria-checked":surfer.onSite,"tabindex":"0"},on:{"click":function($event){return _vm.handleCheckInToggle(surfer, 'onSite')}}},[_c('span',{staticClass:"inline-block h-5 w-5 rounded-full bg-white shadow transform transition ease-in-out duration-200",class:surfer.onSite
										? 'translate-x-5'
										: 'translate-x-0',attrs:{"aria-hidden":"true"}})])]),_c('div',{staticClass:"w-auto"},[_c('span',{staticClass:"relative inline-block flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:shadow-outline",class:surfer.checkInLater
									? 'bg-pear-600'
									: 'bg-gray-400',attrs:{"role":"checkbox","aria-checked":surfer.checkInLater,"tabindex":"0"},on:{"click":function($event){return _vm.handleCheckInToggle(surfer, 'checkInLater')}}},[_c('span',{staticClass:"inline-block h-5 w-5 rounded-full bg-white shadow transform transition ease-in-out duration-200",class:surfer.checkInLater
										? 'translate-x-5'
										: 'translate-x-0',attrs:{"aria-hidden":"true"}})])]),_c('div',{staticClass:"w-auto"},[_c('span',{staticClass:"relative inline-block flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:shadow-outline",class:surfer.noShow
									? 'bg-pear-600'
									: 'bg-gray-400',attrs:{"role":"checkbox","aria-checked":surfer.noShow,"tabindex":"0"},on:{"click":function($event){return _vm.handleCheckInToggle(surfer, 'noShow')}}},[_c('span',{staticClass:"inline-block h-5 w-5 rounded-full bg-white shadow transform transition ease-in-out duration-200",class:surfer.noShow
										? 'translate-x-5'
										: 'translate-x-0',attrs:{"aria-hidden":"true"}})])])])])})],2),_c('section',{staticClass:"px-4 mb-15"},[(_vm.surfersNotCheckIn && _vm.surfersNotCheckIn.length > 0)?_c('p',{staticClass:"mb-5 w-10/12"},[_vm._v(" You can't check-in people over 18, who need to sign a waiver. The following people will need to go through this check-in proccess separately at another terminal, or after you at this terminal. ")]):_vm._e(),_vm._l((_vm.surfersNotCheckIn),function(surfer){return _c('div',{key:surfer.uuid,staticClass:"flex items-center justify-around py-5"},[_c('div',{staticClass:"w-3/12"},[_c('p',[_vm._v(_vm._s(surfer.lastName)+", "+_vm._s(surfer.firstName))]),_c('p',{staticClass:"text-xs",domProps:{"innerHTML":_vm._s(surfer.sessionNames)}})]),_c('div',{staticClass:"w-9/12"},[_c('p',[_vm._v("Session(s) at "+_vm._s(surfer.sessionTimes))])])])})],2),_c('section',{staticClass:"px-4 pb-10"},[_c('button',{staticClass:"w-1/2 md:w-1/3 lg:w-1/4 bg-pear-500 hover:bg-pear-700 hover:border-0 text-sherpa-500 text-center text-sm font-bold py-2 px-3 rounded-full",attrs:{"type":"button","aria-label":"Continue Wave Check In Process"},on:{"click":_vm.checkInSurfers}},[_vm._v(" Continue ")])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h1',{staticClass:"text-2xl"},[_vm._v(" Surf Ops - Customer Check-In ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"px-4 mb-5"},[_c('p',{staticClass:"w-10/12"},[_vm._v(" You can check the following people in, please choose who is present with you now. If people are travelling separately, or not at all, please let us know below. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-1/2 md:w-1/3 flex ml-auto -mr-2 lg:-mr-4 justify-around items-center"},[_c('p',{staticClass:"w-auto text-sm mx-2 lg:mx-4"},[_vm._v(" Check-In Now ")]),_c('p',{staticClass:"w-auto text-sm mx-2 lg:mx-4"},[_vm._v(" Check-In Later ")]),_c('p',{staticClass:"w-auto text-sm mx-2 lg:mx-4"},[_vm._v(" Couldn't Make It ")])])
}]

export { render, staticRenderFns }